import React from 'react';
import { FiltersBarProps } from './filters-bar-type';
import { SelectFilter } from './select-filter';

import styles from './filters-bar-component.module.css';

export const FiltersBar: React.FC<FiltersBarProps> = ({
    filtersList,
    data,
    onFilterChanged,
    children,
    centered,
}: FiltersBarProps) => {
    const handleFilterChange = (property: string, newValue: string): void => {
        const newFilters = [...filtersList];
        if (newFilters) {
            const filter = newFilters.find(f => f.property === property);
            if (filter) {
                filter.value = newValue;
                onFilterChanged(newFilters);
            }
        }
    };

    return (
        <React.Fragment>
            <div className={`${styles.filtersBar} elementNotToPrint ${centered ? styles.centered : ''}`}>
                {filtersList &&
                    filtersList.map(filter => (
                        <SelectFilter
                            key={filter.property}
                            filter={filter}
                            data={data}
                            onFilterChanged={handleFilterChange}
                        />
                    ))}
                <div>{children}</div>
            </div>
        </React.Fragment>
    );
};
