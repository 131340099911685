import React from 'react';
import { NumericCurrencyLabelProps } from '.';
import { NumericLabel } from '..';

export const NumericCurrencyLabel: React.FC<NumericCurrencyLabelProps> = ({
    value,
    label,
    column,
    alignRight,
}: NumericCurrencyLabelProps) => {
    return (
        <NumericLabel value={value} label={label} unit="€" numericPadding={2} column={column} alignRight={alignRight} />
    );
};
