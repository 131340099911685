import React from 'react';
import styles from './numeric-label-component.module.css';
import { NumericLabelProps } from './numeric-label-type';

export const NumericLabel: React.FC<NumericLabelProps> = ({
    value,
    label,
    unit,
    numericPadding = 0,
    column,
    alignRight,
}: NumericLabelProps) => {
    return (
        <React.Fragment>
            <div
                className={`${styles.numericLabel} ${column ? styles.column : ''} ${
                    alignRight ? styles.alignRight : ''
                }`}
            >
                {!label ? null : <span className={styles.label}>{label}</span>}
                <span className={styles.value}>{`${value.toFixed(numericPadding)}${!unit ? '' : ` ${unit}`}`}</span>
            </div>
        </React.Fragment>
    );
};
