import React from 'react';
import { createSliderWithTooltip, Range } from 'rc-slider';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'rc-tooltip/assets/bootstrap.css';
import 'rc-slider/assets/index.css';

import { DualSliderProps } from './dual-slider-type';

import styles from './dual-slider-component.module.css';

const CustomRange = createSliderWithTooltip(Range);

export const DualSlider: React.FC<DualSliderProps> = ({
    minValue,
    selectedMinValue,
    maxValue,
    selectedMaxValue,
    unit,
    onChange,
}: DualSliderProps) => {
    if (!maxValue || minValue === maxValue) {
        return <React.Fragment />;
    }

    const defaultValues: number[] = [];
    if (selectedMinValue !== undefined) {
        defaultValues.push(selectedMinValue);
    }

    if (selectedMaxValue !== undefined) {
        defaultValues.push(selectedMaxValue);
    }

    return (
        <React.Fragment>
            <div className={styles.rangeValues}>
                <span>
                    {selectedMinValue || minValue} {unit}
                </span>
                <span>
                    {selectedMaxValue || maxValue} {unit}
                </span>
            </div>
            <CustomRange
                min={minValue}
                max={maxValue}
                defaultValue={defaultValues}
                onAfterChange={(value: number[]) => {
                    const { 0: newMinValue, 1: newMaxValue } = value;
                    onChange(newMinValue, newMaxValue);
                }}
                tipFormatter={(value: number) => `${value} ${unit}`}
            />
        </React.Fragment>
    );
};
