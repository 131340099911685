/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import { getProductCDNUrl } from 'modules/shared/utils';
import { LoadableImage } from 'modules/shared-ui/components';
import { Carousel } from '../../carousel';
import { ImagesViewerProps } from '../images-viewer-type';

import styles from './images-viewer-mobile-component.module.css';

export const ImagesViewerMobile: React.FC<ImagesViewerProps> = React.memo(
    ({ images, videoUrl, useBox, varStyleThumb, varStyleImg, loaderClassName }: ImagesViewerProps) => {
        if (images && images.length > 0) {
            const [currentIndex, setCurrentIndex] = useState(0);

            return (
                <React.Fragment>
                    <div className={styles.container}>
                        <div className={styles.activeImgContainer}>
                            <div className={useBox ? styles.box : ''}>
                                {currentIndex < images.length ? (
                                    <picture>
                                        <LoadableImage
                                            className={styles.img}
                                            style={varStyleImg}
                                            placeholderClassName={loaderClassName}
                                            alt={images[currentIndex].title}
                                            src={getProductCDNUrl(images[currentIndex].fileName)}
                                        />
                                    </picture>
                                ) : (
                                    <React.Fragment>
                                        <div className={styles.videoContainer}>
                                            <video
                                                src={videoUrl}
                                                autoPlay
                                                controls
                                                controlsList="nodownload noremoteplayback"
                                                loop
                                                muted
                                            />
                                        </div>
                                    </React.Fragment>
                                )}
                            </div>
                        </div>
                        {images && images.length > 1 && (
                            <div className={styles.thumbnails}>
                                <Carousel cards={4} auto={false} catalogMode smallArrows>
                                    {images.map((image, index) => {
                                        return (
                                            <div
                                                key={index}
                                                className={`${styles.box} ${
                                                    currentIndex === index ? styles.active : ''
                                                }`}
                                            >
                                                <picture>
                                                    <LoadableImage
                                                        className={styles.img}
                                                        style={varStyleThumb}
                                                        alt={image.title}
                                                        src={getProductCDNUrl(image.thumbnailFileName)}
                                                        onClick={() => {
                                                            setCurrentIndex(index);
                                                        }}
                                                    />
                                                </picture>
                                            </div>
                                        );
                                    })}
                                    {videoUrl && videoUrl !== '' && (
                                        <div
                                            key={images.length}
                                            className={`${styles.videoBox} ${
                                                currentIndex === images.length ? styles.active : ''
                                            }`}
                                            onClick={() => {
                                                setCurrentIndex(images.length);
                                            }}
                                        >
                                            <div className={`${styles.videoThumbnail}`}>
                                                <video src={videoUrl} />
                                                <div className={styles.videoCircle}>
                                                    <div className={styles.videoArrow} />
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </Carousel>
                            </div>
                        )}
                    </div>
                </React.Fragment>
            );
        }
        return <React.Fragment></React.Fragment>;
    },
);
