import React from 'react';
import { buildComponentArray } from 'modules/shared/utils';
import { NumberedBulletProps, NumberedBulletsProps } from './numbered-bullets-type';

import styles from './numbered-bullets-component.module.css';

const NumberedBullet: React.FC<NumberedBulletProps> = ({ value, inverted }: NumberedBulletProps) => {
    return (
        <React.Fragment>
            <div className={`${styles.circle} ${inverted && styles.inverted}`}>
                <div className={styles.bullet}>{value + 1}</div>
            </div>
        </React.Fragment>
    );
};

export const NumberedBullets: React.FC<NumberedBulletsProps> = ({
    initialValue: wrappedInitialValue,
    maxValue,
}: NumberedBulletsProps) => {
    const initialValue = wrappedInitialValue || 0;
    const array = Array.from({ length: maxValue }, (_, i) => i);

    return (
        <React.Fragment>
            <div className={styles.bulletsContainer}>
                {buildComponentArray<number>(
                    array,
                    (index: number, key: number): JSX.Element => {
                        return (
                            // eslint-disable-next-line react/no-array-index-key
                            <NumberedBullet key={key} value={index} inverted={index < initialValue} />
                        );
                    },
                )}
                <div className={styles.separator} />
            </div>
        </React.Fragment>
    );
};
