import React from 'react';
import { Responsive } from 'vendors/semantic-ui';

import { configUI } from 'config';
import { ImagesViewerDesktop } from './desktop';
import { ImagesViewerMobile } from './mobile';
import { ImagesViewerProps } from './images-viewer-type';

export const ImagesViewer: React.FC<ImagesViewerProps> = React.memo((props: ImagesViewerProps) => {
    return (
        <React.Fragment>
            <Responsive as={React.Fragment} maxWidth={configUI.responsive.mobile.maxWidth}>
                <ImagesViewerMobile {...props} />
            </Responsive>
            <Responsive as={React.Fragment} minWidth={configUI.responsive.tablet.minWidth}>
                <ImagesViewerDesktop {...props} />
            </Responsive>
        </React.Fragment>
    );
});
