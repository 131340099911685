import React from 'react';
import Slider, { Settings } from 'react-slick';

import { configUI } from 'config';

import { CarouselProps } from './carousel-type';
import { Arrow, ArrowDirection } from './components';
import styles from './carousel-component.module.css';

export const Carousel: React.FC<CarouselProps> = ({
    cards,
    auto,
    children,
    initialSlide,
    dark,
    vertical,
    catalogMode = false,
    pictoMode,
    smallArrows = false,
    type = 'default',
    hideDots = false,
    onSlideChange,
}: CarouselProps) => {
    const childrenCount: number = React.Children.count(children);
    const enoughChildren: boolean = childrenCount >= cards;
    const singleMode: boolean = cards === 1;
    const slides: number = enoughChildren ? cards : childrenCount;
    let settings: Settings = {
        infinite: !vertical,
        dots: !hideDots && singleMode,
        dotsClass: `slick-dots ${styles.printDots} ${dark ? styles.dark : ''}`,
        customPaging: () => (
            <button type="button" className={`${styles.printDot} ${dark ? styles.dark : ''}`} aria-hidden />
        ),
        arrows: true,
        speed: 500,
        autoplay: auto,
        autoplaySpeed: 4500,
        pauseOnHover: true,
        slidesToShow: slides,
        slidesToScroll: 1,
        adaptiveHeight: singleMode,
        prevArrow: vertical ? (
            <Arrow direction={ArrowDirection.Up} dark={dark} />
        ) : (
            <Arrow direction={ArrowDirection.Left} dark={dark} small={smallArrows} />
        ),
        nextArrow: vertical ? (
            <Arrow direction={ArrowDirection.Down} dark={dark} />
        ) : (
            <Arrow direction={ArrowDirection.Right} dark={dark} small={smallArrows} />
        ),
        responsive: singleMode ? undefined : configUI.carousel.responsive,
    };

    if (!vertical && settings.responsive) {
        settings.responsive.map(responsive => {
            const respSettings = responsive.settings as Settings;
            if (catalogMode) {
                respSettings.slidesToShow = cards;
                respSettings.centerMode = !catalogMode;
                respSettings.infinite = false;
                respSettings.arrows = smallArrows;
            }
            if (!enoughChildren) {
                if (respSettings.slidesToShow && respSettings.slidesToShow > childrenCount) {
                    respSettings.slidesToShow = childrenCount;
                    respSettings.initialSlide = 1;
                }
            }
            if (pictoMode !== undefined) {
                respSettings.arrows = pictoMode;
            }
            return { ...responsive, respSettings };
        });
    }

    if (initialSlide) {
        settings = { ...settings, initialSlide };
    }
    if (vertical) {
        settings = { ...settings, vertical };
        delete settings.responsive;
    }

    return (
        <div
            className={`${vertical ? 'vertical' : 'horizontal'} ${styles.carouselContainer} ${styles.cardContainer} ${
                type === 'productCarousel' ? styles.productCarousel : ''
            }`}
        >
            <Slider {...settings} afterChange={onSlideChange}>
                {children}
            </Slider>
        </div>
    );
};
