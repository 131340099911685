import React from 'react';
import { ArrowProps, ArrowDirection } from './arrow-type';

import styles from './arrow-component.module.css';

export const Arrow: React.FC<ArrowProps> = ({ className, style, onClick, direction, dark, small }: ArrowProps) => {
    let directionClass = small ? styles.smallLeftArrow : styles.leftArrow;
    switch (direction) {
        case ArrowDirection.Right:
            directionClass = small ? styles.smallRightArrow : styles.rightArrow;
            break;
        case ArrowDirection.Up:
            directionClass = styles.upArrow;
            break;
        case ArrowDirection.Down:
            directionClass = styles.downArrow;
            break;
        default:
            break;
    }
    return (
        <button
            className={`${className} ${directionClass} ${styles.customArrow} ${dark ? styles.dark : ''}`}
            style={style}
            type="button"
            onClick={onClick}
        />
    );
};
