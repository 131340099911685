import { CustomArrowProps } from 'react-slick';

export interface ArrowProps extends CustomArrowProps {
    direction: ArrowDirection;
    dark?: boolean;
    small?: boolean;
}

export enum ArrowDirection {
    Left = 1,
    Right = 2,
    Up = 3,
    Down = 4,
}
