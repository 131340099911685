import React, { useState, useEffect, useCallback } from 'react';
import { Input } from 'modules/shared-ui-advanced/vendors/semantic-ui';
import styles from './numeric-textbox-component.module.css';
import { NumericTextboxProps } from './numeric-textbox-type';

export const NumericTextbox: React.FC<NumericTextboxProps> = ({
    name,
    value,
    onValueChanged,
    min = 0,
    max,
    editable,
    label,
    unit,
    numericPadding = 0,
}: NumericTextboxProps) => {
    const [selectedValue, setSelectedValue] = useState(value.toFixed(numericPadding));

    useEffect(() => {
        setSelectedValue(value.toFixed(numericPadding));
    }, [value, numericPadding]);

    const add = useCallback(
        (e: React.MouseEvent): void => {
            e.preventDefault();
            const valueNumber = Number.parseFloat(selectedValue);

            if (max && valueNumber >= max) return;

            const nextValue = valueNumber + 1;
            setSelectedValue(nextValue.toFixed(numericPadding));
            onValueChanged(nextValue);
        },
        [max, selectedValue, numericPadding, onValueChanged],
    );

    const remove = useCallback(
        (e: React.MouseEvent): void => {
            e.preventDefault();
            const valueNumber = Number.parseFloat(selectedValue);

            if (valueNumber <= min) return;

            const nextValue = valueNumber - 1;
            setSelectedValue(nextValue.toFixed(numericPadding));
            onValueChanged(nextValue);
        },
        [min, selectedValue, numericPadding, onValueChanged],
    );

    const updateSelectedValue = useCallback(
        // -- on met à jour la valeur quand l'input est blur car sinon la saisie est perturbée avec les nombres décimaux
        (valueStr: string) => {
            // -- on gère le cas ou les utilisateurs utilisent des virgules à la place des points pour les nombres décimaux
            let valueNumber = Number.parseFloat(valueStr.replace(',', '.'));

            const roundMultiplier = 10 ** numericPadding;
            valueNumber = Math.round(valueNumber * roundMultiplier) / roundMultiplier;

            let nextValue: number;
            if (Number.isNaN(valueNumber)) {
                nextValue = min;
            } else if (valueNumber < min) {
                nextValue = min;
            } else if (max && valueNumber > max) {
                nextValue = max;
            } else {
                nextValue = valueNumber;
            }

            // -- on est obligé de set la valeur ici meme si elle est aussi set par le parent, car si le parent remet la valeur précédente (max dépassé par exemple), le useEffect n'est pas déclanché
            setSelectedValue(nextValue.toFixed(numericPadding));
            onValueChanged(nextValue);
        },
        [min, max, numericPadding, onValueChanged],
    );

    return (
        <React.Fragment>
            <div className={styles.numericTextbox}>
                {label && <span className={styles.label}>{label}</span>}
                <div className={styles.inputBox}>
                    {numericPadding !== 0 ? null : (
                        <button className={styles.btn} disabled={value === min} onClick={remove} type="button">
                            -
                        </button>
                    )}
                    <div className={styles.inputContainer}>
                        <Input
                            name={name}
                            type="number"
                            className={`${styles.input} ${numericPadding === 0 ? '' : styles.inputWithoutButtons}`}
                            value={selectedValue}
                            min={min}
                            max={max}
                            onChange={e => setSelectedValue(e.target.value)}
                            onBlur={(e: React.FocusEvent<HTMLInputElement>) => updateSelectedValue(e.target.value)}
                            disabled={editable === false}
                            step="any"
                        />
                        {!unit ? null : <span className={styles.unit}>{unit}</span>}
                    </div>
                    {numericPadding !== 0 ? null : (
                        <button className={styles.btn} disabled={value === max} onClick={add} type="button">
                            +
                        </button>
                    )}
                </div>
            </div>
        </React.Fragment>
    );
};
