/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import { Modal, Popup } from 'vendors/semantic-ui';

import { getProductCDNUrl } from 'modules/shared/utils';
import { LoadableImage } from 'modules/shared-ui/components';
import { Carousel } from '../../carousel';
import { ImagesViewerProps } from '../images-viewer-type';

import styles from './images-viewer-desktop-component.module.css';

export const ImagesViewerDesktop: React.FC<ImagesViewerProps> = React.memo(
    ({ images, videoUrl, useBox, varStyleThumb, varStyleImg, loaderClassName }: ImagesViewerProps) => {
        if (images && images.length > 0) {
            const [currentIndex, setCurrentIndex] = useState(0);
            const [currentModalIndex, setCurrentModalIndex] = useState(0);
            const [open, setOpen] = useState(false);

            return (
                <React.Fragment>
                    <div className={styles.container}>
                        {images && images.length > 1 && (
                            <div className={styles.thumbnails}>
                                <Carousel cards={4} auto={false} vertical>
                                    {images.slice(0, 6).map((image, index) => {
                                        return (
                                            <div
                                                key={index}
                                                className={`${styles.box} ${
                                                    currentIndex === index ? styles.active : ''
                                                }`}
                                            >
                                                <picture>
                                                    <LoadableImage
                                                        className={styles.img}
                                                        style={varStyleThumb}
                                                        alt={image.title}
                                                        src={getProductCDNUrl(image.thumbnailFileName)}
                                                        onMouseEnter={() => {
                                                            setCurrentIndex(index);
                                                            setCurrentModalIndex(index);
                                                        }}
                                                    />
                                                </picture>
                                            </div>
                                        );
                                    })}
                                    {videoUrl && videoUrl !== '' && (
                                        <div
                                            key={images.length}
                                            className={`${styles.videoBox} ${
                                                currentIndex === images.length ? styles.active : ''
                                            }`}
                                            onMouseEnter={() => {
                                                setCurrentIndex(images.length);
                                            }}
                                        >
                                            <div className={styles.videoThumbnail}>
                                                <video src={videoUrl} />
                                                <div className={styles.videoCircle}>
                                                    <div className={styles.videoArrow} />
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </Carousel>
                            </div>
                        )}
                        <div className={styles.activeImgContainer}>
                            {currentIndex < images.length ? (
                                <React.Fragment>
                                    <div className={useBox ? styles.box : ''} onClick={() => setOpen(true)}>
                                        <picture className={styles.modalPictureContainer}>
                                            <LoadableImage
                                                className={styles.img}
                                                style={varStyleImg}
                                                alt={images[currentIndex].title || ''}
                                                src={getProductCDNUrl(images[currentIndex].largeFileName)}
                                                placeholderClassName={loaderClassName}
                                            />
                                            <div className={styles.modalZoomContainer}>
                                                <Popup
                                                    header="Agrandir l'image"
                                                    position="top center"
                                                    trigger={<div className={styles.modalZoomBtn} />}
                                                />
                                            </div>
                                        </picture>
                                    </div>
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    <div className={styles.videoContainer}>
                                        <video
                                            src={videoUrl}
                                            autoPlay
                                            controls
                                            controlsList="nodownload noremoteplayback"
                                            loop
                                            muted
                                        />
                                    </div>
                                </React.Fragment>
                            )}
                        </div>
                        <Modal className={styles.modal} open={open} onClose={() => setOpen(false)} closeIcon>
                            <Modal.Content>
                                <div className={styles.modalContent}>
                                    {images && images.length > 1 && (
                                        <div className={styles.thumbnails}>
                                            <Carousel cards={4} auto={false} vertical>
                                                {images.slice(0, 6).map((image, index) => {
                                                    return (
                                                        <div
                                                            key={index}
                                                            className={`${styles.box} ${
                                                                currentIndex === index ? styles.active : ''
                                                            }`}
                                                        >
                                                            <picture>
                                                                <LoadableImage
                                                                    className={styles.img}
                                                                    style={varStyleThumb}
                                                                    alt={image.title}
                                                                    src={getProductCDNUrl(image.thumbnailFileName)}
                                                                    onMouseEnter={() => {
                                                                        setCurrentIndex(index);
                                                                        setCurrentModalIndex(index);
                                                                    }}
                                                                />
                                                            </picture>
                                                        </div>
                                                    );
                                                })}
                                            </Carousel>
                                        </div>
                                    )}
                                    <div className={styles.modalCurrentImgContainer}>
                                        <div className={useBox ? styles.box : ''}>
                                            <picture>
                                                <LoadableImage
                                                    className={styles.img}
                                                    alt={images[currentModalIndex].title}
                                                    src={getProductCDNUrl(images[currentModalIndex].largeFileName)}
                                                />
                                            </picture>
                                        </div>
                                    </div>
                                </div>
                            </Modal.Content>
                        </Modal>
                    </div>
                </React.Fragment>
            );
        }
        return <React.Fragment></React.Fragment>;
    },
);
