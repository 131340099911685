import React from 'react';
import { Label, Icon } from 'vendors/semantic-ui';
import { TagListProps } from '.';

import styles from './tag-list-component.module.css';

export const TagList: React.FC<TagListProps> = ({ tags, onDeleteTagClick, onDeleteAllClick }: TagListProps) => {
    if (!tags || tags.length === 0) {
        return <React.Fragment />;
    }

    return (
        <React.Fragment>
            {tags &&
                tags.map(t => {
                    return (
                        <Label
                            className="filter-tag-label"
                            key={t.value}
                            as="a"
                            onClick={() => {
                                if (onDeleteTagClick) {
                                    onDeleteTagClick(t.value);
                                }
                            }}
                        >
                            {t.title}
                            <Icon name="delete" />
                        </Label>
                    );
                })}
            {onDeleteAllClick && tags && tags.length > 1 && (
                <Label className={`${styles.deleteAll} filter-tag-label`} onClick={onDeleteAllClick} as="a">
                    Tout supprimer
                    <Icon name="delete" />
                </Label>
            )}
        </React.Fragment>
    );
};
