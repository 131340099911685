import React, { useEffect, useState } from 'react';
import { Icon } from 'vendors/semantic-ui';
import BaseAccordion from 'semantic-ui-react/dist/commonjs/modules/Accordion';
import { AccordionTitleProps as LocalAccordionTitleProps } from 'semantic-ui-react/dist/commonjs/modules/Accordion/AccordionTitle';

import { AccordionProps } from '.';

import styles from './accordion-component.module.css';

export type AccordionTitleProps = LocalAccordionTitleProps;

export const Accordion: React.FC<AccordionProps> = React.memo(
    ({ items, activeIndexs, onActiveIndexsChange, isExclusive, defaultOpened }: AccordionProps) => {
        const [localActiveIndexs, setLocalActiveIndexs] = useState<number[]>([]);

        useEffect(() => {
            if (activeIndexs) {
                setLocalActiveIndexs(activeIndexs);
            } else if (defaultOpened) {
                setLocalActiveIndexs(items ? items.map((_m, i) => i) : []);
            }
        }, [items, defaultOpened, activeIndexs]);

        const handleClick = (_event: React.MouseEvent<HTMLDivElement>, data: AccordionTitleProps): void => {
            const { index } = data;
            const securedIndex = !index ? 0 : Number(index);

            const securedIndexs = [...localActiveIndexs];
            const existingIndex = securedIndexs.indexOf(securedIndex);
            if (existingIndex === -1) {
                securedIndexs.push(securedIndex);
            } else {
                securedIndexs.splice(existingIndex, 1);
            }

            if (onActiveIndexsChange) {
                onActiveIndexsChange(securedIndexs);
            }
        };

        return (
            <BaseAccordion className={styles.accordion} exclusive={!!isExclusive}>
                {items &&
                    items.map((item, index) => {
                        const isOpened = localActiveIndexs.indexOf(index) > -1;
                        return (
                            // eslint-disable-next-line react/no-array-index-key
                            <React.Fragment key={index}>
                                <BaseAccordion.Title index={index} className={styles.title} onClick={handleClick}>
                                    <Icon index={index} name="dropdown" className={isOpened ? styles.rotate : ''} />
                                    {item.title}
                                </BaseAccordion.Title>
                                <BaseAccordion.Content
                                    style={{ paddingTop: 0 }}
                                    active={isOpened}
                                    className={styles.content}
                                >
                                    {item.component}
                                </BaseAccordion.Content>
                            </React.Fragment>
                        );
                    })}
            </BaseAccordion>
        );
    },
);
