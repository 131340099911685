import React, { useState, useEffect } from 'react';
import { Select } from 'vendors/semantic-ui';
import styles from './select-filter-component.module.css';
import { SelectFilterProps, DropDownItem } from './select-filter-type';
import { FilterTypeValues, SortTypeValues } from '../filters-bar-type';

export const SelectFilter: React.FC<SelectFilterProps> = ({ filter, data, onFilterChanged }: SelectFilterProps) => {
    const [selectedValue, setSelectedValue] = useState(filter.value || '');

    useEffect(() => {
        onFilterChanged(filter.property, selectedValue);
        // -- ne pas ajouter les dependances indiqué dans les warning (déclanche une boucle infinie)
    }, [selectedValue]);

    const unit = filter.unit || '';

    let options = [] as DropDownItem[];
    if (filter.type === FilterTypeValues.VALUE) {
        options = [{ text: filter.emptyLabel || filter.label || 'Tous', value: '' }];
        data.forEach(item => {
            const descriptor = Object.getOwnPropertyDescriptor(item, filter.property);
            if (descriptor && descriptor.value) {
                if (!options.find(o => o.value === descriptor.value))
                    options.push({ text: `${descriptor.value}${unit}`, value: descriptor.value });
            }
        });
    } else if (filter.type === FilterTypeValues.SORT) {
        options = [
            { text: filter.emptyLabel || filter.label || 'Tous', value: '' },
            { text: 'Croissant', value: SortTypeValues.ASC },
            { text: 'Décroissant', value: SortTypeValues.DESC },
        ] as DropDownItem[];
    } else if (filter.type === FilterTypeValues.INTERVAL) {
        options = [{ text: filter.emptyLabel || filter.label || 'Tous', value: '' }];

        if (filter.intervals) {
            filter.intervals.forEach(item => {
                // -- on ne peut pas utiliser !item.min car ça pose problème si item.min === 0
                if (item.max === undefined && item.min === undefined) {
                    return;
                }

                if (item.max !== undefined && item.min !== undefined && item.min > item.max) {
                    return;
                }

                const filterHasResult = data.some(o => {
                    const descriptor = Object.getOwnPropertyDescriptor(o, filter.property);

                    if (!descriptor || typeof descriptor.value !== 'number') {
                        return false;
                    }

                    return (
                        (item.min === undefined || (descriptor.value as number) >= item.min) &&
                        (item.max === undefined || (descriptor.value as number) <= item.max)
                    );
                });

                if (!filterHasResult) {
                    return;
                }

                if (item.min !== undefined && item.max !== undefined) {
                    const optionText = `${item.min}${unit} - ${item.max}${unit}`;
                    const optionValue = `${item.min}/${item.max}`;
                    options.push({ text: optionText, value: optionValue });
                } else if (item.min !== undefined && item.max === undefined) {
                    const optionText = `Plus de ${item.min}${unit}`;
                    const optionValue = `${item.min}/`;
                    options.push({ text: optionText, value: optionValue });
                } else if (item.min === undefined && item.max !== undefined) {
                    const optionText = `Moins de ${item.max}${unit}`;
                    const optionValue = `/${item.max}`;
                    options.push({ text: optionText, value: optionValue });
                }
            });
        }
    } else if (filter.type === FilterTypeValues.PROPERTY) {
        options = [{ text: filter.emptyLabel || filter.label || 'Aucun', value: '' }];
        data.forEach(item => {
            Object.keys(item).forEach(key => {
                const lowerKey = key.toLocaleLowerCase();
                if (!options.find(o => o.value === key) && filter.excludes && !filter.excludes.includes(lowerKey)) {
                    let text = key;
                    if (filter.translates) {
                        const translation = filter.translates.find(t => t.key === lowerKey);
                        if (translation) text = translation.value;
                    }
                    options.push({ text: `Par ${text}`.toUpperCase(), value: key });
                }
            });
        });
    }

    return (
        <React.Fragment>
            <Select
                className={`${styles.filterSelect} custom-selector catalog`}
                placeholder={filter.label || 'Selectionner'}
                options={options}
                name={filter.property}
                onChange={(e, d) => {
                    setSelectedValue(d.value as string);
                }}
            />
        </React.Fragment>
    );
};
