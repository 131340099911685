export type FilterType = 0 | 1 | 2 | 3;
const VALUE_FILTER_TYPE: FilterType = 0;
const SORT_FILTER_TYPE: FilterType = 1;
const INTERVAL_FILTER_TYPE: FilterType = 2;
const PROPERTY_FILTER_TYPE: FilterType = 3;

export const FilterTypeValues = {
    VALUE: VALUE_FILTER_TYPE,
    SORT: SORT_FILTER_TYPE,
    INTERVAL: INTERVAL_FILTER_TYPE,
    PROPERTY: PROPERTY_FILTER_TYPE,
};

export type SortType = 'asc' | 'desc';
const ASC_SORT_TYPE: SortType = 'asc';
const DESC_SORT_TYPE: SortType = 'desc';
export const SortTypeValues = {
    ASC: ASC_SORT_TYPE,
    DESC: DESC_SORT_TYPE,
};

interface Translation {
    key: string;
    value: string;
}

export interface Filter {
    property: string;
    type: FilterType;
    label?: string;
    emptyLabel?: string;
    value?: string;
    defaultValue?: string;
    unit?: string;
    intervals?: FilterInterval[];
    excludes?: string[];
    translates?: Translation[];
}

export interface FilterInterval {
    min?: number;
    max?: number;
}

export interface FiltersBarProps {
    filtersList: Filter[];
    data: object[];
    onFilterChanged: (newFilters: Filter[]) => void;
    children?: React.ReactNode;
    centered?: boolean;
}
