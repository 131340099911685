/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';

import { HtmlContainer } from 'modules/shared-ui/components';

import { ExpanderProps } from './expander-type';

import styles from './expander-component.module.css';

export const Expander: React.FC<ExpanderProps> = ({
    defaultState,
    headerTitle,
    subTitle,
    secondaryHeaderTitle,
    children,
    secondaryChildren,
    style,
    titleStyle,
    contentStyle,
    secondaryContentStyle,
    isTextHtml,
    hideToggle = false,
    shortTitle = false,
}: ExpanderProps) => {
    const [toggled, setToggled] = useState(defaultState);
    return (
        <React.Fragment>
            <div className={styles.wrapper} style={style}>
                <div
                    className={`${styles.header} ${hideToggle ? styles.noToggle : ''} ${
                        shortTitle ? styles.short : ''
                    } `}
                    onClick={() => {
                        setToggled(!toggled);
                    }}
                >
                    {!hideToggle && <span className={toggled ? styles.toggleMinus : styles.togglePlus} />}

                    {headerTitle && (
                        <span className={styles.headerTitle} style={titleStyle}>
                            {isTextHtml && typeof headerTitle === 'string' ? (
                                <HtmlContainer htmlContent={headerTitle} />
                            ) : (
                                headerTitle
                            )}
                        </span>
                    )}

                    {subTitle && (
                        <span className={styles.subTitle}>
                            {isTextHtml ? <HtmlContainer htmlContent={subTitle} /> : subTitle}
                        </span>
                    )}

                    {secondaryHeaderTitle && (
                        <span className={styles.secondaryHeaderTitle}>
                            {typeof secondaryHeaderTitle === 'string' && isTextHtml ? (
                                <HtmlContainer htmlContent={secondaryHeaderTitle as string} />
                            ) : (
                                secondaryHeaderTitle
                            )}
                        </span>
                    )}
                </div>

                {toggled && (
                    <React.Fragment>
                        <div className={styles.content} style={contentStyle}>
                            {typeof children === 'string' ? <HtmlContainer htmlContent={children} /> : children}
                        </div>
                        {!!secondaryChildren && (
                            <React.Fragment>
                                <br />
                                <div style={secondaryContentStyle}>
                                    {typeof secondaryChildren === 'string' ? (
                                        <HtmlContainer htmlContent={secondaryChildren} />
                                    ) : (
                                        secondaryChildren
                                    )}
                                </div>
                            </React.Fragment>
                        )}
                    </React.Fragment>
                )}
            </div>
        </React.Fragment>
    );
};
