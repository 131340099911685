import React, { useState } from 'react';

import { HtmlContainer } from 'modules/shared-ui/components';
import { TabProps } from './tab-type';

import styles from './tab-component.module.css';

export const Tab: React.FC<TabProps> = React.memo(({ items }: TabProps) => {
    const [index, setIndex] = useState(0);
    if (!items || items.length < 1) {
        return <React.Fragment></React.Fragment>;
    }

    return (
        <React.Fragment>
            <div className={styles.container}>
                <div className={styles.tabHeader}>
                    {items.map((item, i) => {
                        return (
                            <div className={styles.tabHeaderItem}>
                                <button
                                    type="button"
                                    className={`${styles.tabHeaderItemBtn} ${index === i ? styles.activeTab : ''}`}
                                    onClick={() => setIndex(i)}
                                >
                                    {item.header}
                                </button>
                            </div>
                        );
                    })}
                </div>
                <div className={styles.tabContent}>
                    <HtmlContainer
                        style={items[index].header === 'Description' ? { display: 'inline' } : {}}
                        htmlContent={items[index].content}
                    />
                </div>
            </div>
        </React.Fragment>
    );
});
