import React from 'react';
import { NumericCurrencyTextboxProps } from '.';
import { NumericTextbox } from '..';

export const NumericCurrencyTextbox: React.FC<NumericCurrencyTextboxProps> = ({
    value,
    onValueChanged,
    min,
    max,
    editable,
    label,
}: NumericCurrencyTextboxProps) => {
    return (
        <NumericTextbox
            value={value}
            onValueChanged={onValueChanged}
            min={min}
            max={max}
            editable={editable}
            label={label}
            unit="€"
            numericPadding={2}
        />
    );
};
