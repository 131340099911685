import React from 'react';
import { NumericColoredValueProps } from './numeric-colored-value-type';

export const NumericColoredValue: React.FC<NumericColoredValueProps> = ({
    value,
    unit = '€',
    numericPadding = 0,
}: NumericColoredValueProps) => {
    return (
        <span style={{ color: `${value < 0 ? '#d10056' : 'inherit'}` }}>
            {value.toFixed(numericPadding)} {unit}
        </span>
    );
};
